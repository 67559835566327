import React from "react";
import { CardType } from "src/backend";
import { PrincipalFormsStepper } from 'src/components/loans/principal-forms-stepper';

import { CongratulationCard } from "../applicant/congratulation-card";
import SoftCreditCheckForm from "../applicant/soft-credit-check-form/soft-credit-check-form";
import { UploadDriverLicense } from "../applicant/upload-driver-license";
import { useBorrowerOnboarding } from "./borrower-onboarding.hook";
import { BorrowerOnboardingStyles } from "./borrower-onboarding.styles";

export const BorrowerOnboarding = () => {
    const { state, onStepChange, onGoToNext } = useBorrowerOnboarding();

    return <BorrowerOnboardingStyles.Wrapper>
        <BorrowerOnboardingStyles.Container maxWidth="xl">
            <BorrowerOnboardingStyles.Heading>
                Onboarding
            </BorrowerOnboardingStyles.Heading>
            <PrincipalFormsStepper
                steps={state.steps}
                activeStep={state.activeStep}
                onChange={onStepChange} />
            {/* <BorrowerOnboardingStyles.LinearProgressContainer>
                <BorrowerOnboardingStyles.LinearProgressLabel>
                    Progress {state.progressValue}%
                </BorrowerOnboardingStyles.LinearProgressLabel>
                <BorrowerOnboardingStyles.LinearProgress
                    color='success'
                    variant='determinate'
                    value={state.progressValue} />
            </BorrowerOnboardingStyles.LinearProgressContainer> */}
            {(state.currentTab === CardType.DRIVERS_LICENSE && state.currentCard) &&
                <UploadDriverLicense
                    onGoToNext={onGoToNext}
                    status={state.currentCard.status} />}
            {[CardType.SOFT_CREDIT_PULL, CardType.DEMOGRAPHICS].includes(state.currentTab) &&
                <SoftCreditCheckForm
                    status={state.currentCard.status} />}
            {(state.currentTab === CardType.CONGRATULATIONS && state.currentCard) &&
                <CongratulationCard
                    title={state.currentCard.title} subTitle={state.currentCard.subtitle} />}
        </BorrowerOnboardingStyles.Container>
    </BorrowerOnboardingStyles.Wrapper>
};