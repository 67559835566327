import { LENDER_VIEW, PRINCIPAL_KANBAN_VIEW, PRINCIPAL_VIEW } from 'src/constants/person';
import { applicantSelector, viewTypeSelector } from 'src/slices/view';
import { useSelector } from 'src/store';

type DashboardLayout = typeof LENDER_VIEW | typeof PRINCIPAL_VIEW | typeof PRINCIPAL_KANBAN_VIEW;


export const useDashboardLayout = (): DashboardLayout => {
    const viewType = useSelector(viewTypeSelector);
    const applicant = useSelector(applicantSelector)
    const isCurrentUserLender = viewType === LENDER_VIEW;
    const applicantWizardDone = !applicant?.onboardNeeded;

    if (!viewType) {
        return null;
    }

    if (isCurrentUserLender) {
        return LENDER_VIEW;
    }

    if (!applicantWizardDone) {
        return PRINCIPAL_VIEW;
    }

    return PRINCIPAL_KANBAN_VIEW;
}