import { useRouter } from "next/router";
import { useEffect } from "react";
import { PRINCIPAL_KANBAN_VIEW, PRINCIPAL_VIEW } from "src/constants/person";
import { Route } from "src/constants/ui";
import { viewTypeSelector } from "src/slices/view";
import { useSelector } from "src/store";

import { useDashboardLayout } from "./use-dashboard-layout";
import { useLoans } from "./use-loans"

export const useRedirectBorrowerSingleLoan = () => {
    const viewType = useSelector(viewTypeSelector);
    const { allLoans } = useLoans({ fetchLoans: viewType === PRINCIPAL_VIEW });
    const { replace } = useRouter();
    const dashboardViewType = useDashboardLayout();
    const isCurrentUserPrincipal = viewType === PRINCIPAL_VIEW;
    const shouldRedirect = isCurrentUserPrincipal && dashboardViewType === PRINCIPAL_KANBAN_VIEW;

    useEffect(() => {
        if (allLoans.length === 1 && shouldRedirect) {
            replace({
                pathname: Route.SINGLE_LOAN,
                query: {
                    loanId: allLoans[0].id
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allLoans.length, shouldRedirect]);
}