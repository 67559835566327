import { useRouter } from "next/router";
import React from "react";
import { Card, CardType } from "src/backend";
import { Route } from "src/constants/ui";
import { useMounted } from "src/hooks/use-mounted";
import { cardsSelector, getView } from "src/slices/view";
import { useDispatch, useSelector } from "src/store";
import { intervalManager } from 'src/utils';

import { BorrowerOnboardingReducer, BorrowerOnboardingReducerInitialState } from "./borrower-onboarding.reducer";

export const useBorrowerOnboarding = () => {
    const [state, dispatch] = React.useReducer(BorrowerOnboardingReducer, BorrowerOnboardingReducerInitialState)
    const router = useRouter();
    const reduxDispatch = useDispatch();
    const isMounted = useMounted();
    const cards = useSelector(cardsSelector);

    React.useEffect(() => {
        if (isMounted()) {
            intervalManager.set('getView', () => {
                reduxDispatch(getView())
            });
        }
        return () => {
            intervalManager.clear('getView');
        }
    }, [reduxDispatch, isMounted])

    const loansStatus = state.steps.map((step) => step.status).join().length;

    React.useEffect(() => {
        if (!router.query.tab) {
            const expandedCard = state.steps.find((step) => step.expanded);
            router.replace({ pathname: Route.HOME, query: { tab: expandedCard ? expandedCard.cardType : CardType.DRIVERS_LICENSE } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.replace, state.steps, router.query.tab]);

    // set displayed card based on query param  [ tab ]
    React.useEffect(() => {
        const expandedCardIndex = state.steps.findIndex(card => router.query.tab === card.cardType);
        const expandedCard = state.steps?.[expandedCardIndex];
        if (expandedCard && isMounted()) {
            dispatch({ type: 'SET_CURRENT_CARD', payload: expandedCard });
            dispatch({ type: 'SET_ACTIVE_STEP', payload: expandedCardIndex });
            dispatch({ type: 'SET_CURRENT_TAB', payload: expandedCard.cardType });
            dispatch({ type: 'SET_TAB_USED', payload: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query.tab, loansStatus, isMounted, state.steps?.length]);

    React.useEffect(() => {
        if (isMounted()) {
            cards.forEach(card => {
                if (card.cardType === CardType.ONBOARDING) {
                    dispatch({
                        type: 'SET_STEPS',
                        payload: card.cards
                    });
                    dispatch({
                        type: 'SET_PROGRESS_VALUE',
                        payload: card.percentageDone
                    });
                }
            })
        }
    }, [cards, isMounted]);

    const handleStepChange = (card: Card<any>) => {
        dispatch({
            type: 'SET_CURRENT_CARD',
            payload: card
        })
        router.push({
            pathname: Route.HOME,
            query: {
                tab: card.cardType
            }
        });
    }

    const handleGoToNext = async () => {
        const data = await reduxDispatch(getView());
        const steps = data.cards.find(card => card.cardType === CardType.ONBOARDING)?.cards;
        const nextCard = steps.find((_card, index) => index > 0);

        if (nextCard) {
            router.replace({
                pathname: Route.HOME,
                query: {
                    tab: nextCard ? nextCard.cardType : CardType.DRIVERS_LICENSE
                }
            });
        }
    }
    return {
        state,
        onStepChange: handleStepChange,
        onGoToNext: handleGoToNext
    }
};