import { Card, CardContent, Typography } from '@mui/material';

type PropsType = {
    title: string;
    subTitle: string;
}

export const CongratulationCard = ({ title, subTitle }: PropsType) => <Card>
    <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
            {title}
        </Typography>
        <Typography color="textPrimary" variant="subtitle2" >
            {subTitle}
        </Typography>
    </CardContent>
</Card>