import { Card, CardType } from "src/backend";

interface StepsState {
    isTabUsed: boolean;
    activeStep: number;
    currentCard: Card<any> | null;
    currentTab: CardType;
    steps: Card<any>[];
    progressValue: number;
}

type Action = { type: 'SET_STEPS', payload: Card<any>[] } |
{ type: 'SET_ACTIVE_STEP', payload: number } |
{ type: 'SET_CURRENT_CARD', payload: Card<any> } |
{ type: 'SET_CURRENT_TAB', payload: CardType } |
{ type: 'SET_PROGRESS_VALUE', payload: number } |
{ type: 'SET_TAB_USED', payload: boolean };

export const BorrowerOnboardingReducerInitialState: StepsState = {
    isTabUsed: false,
    activeStep: 0,
    currentCard: null,
    currentTab: CardType.DRIVERS_LICENSE,
    steps: [],
    progressValue: 0,
}

export const BorrowerOnboardingReducer = (state: StepsState, action: Action) => {
    switch (action.type) {
        case 'SET_STEPS':
            return {
                ...state,
                steps: action.payload
            };
        case 'SET_ACTIVE_STEP':
            return {
                ...state,
                activeStep: action.payload
            };
        case 'SET_CURRENT_CARD':
            return {
                ...state,
                currentCard: action.payload
            };
        case 'SET_CURRENT_TAB':
            return {
                ...state,
                currentTab: action.payload
            };
        case 'SET_PROGRESS_VALUE':
            return {
                ...state,
                progressValue: action.payload
            };
        case 'SET_TAB_USED':
            return {
                ...state,
                isTabUsed: action.payload
            };
        default:
            return state;
    }
}