import { Check, Send } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
    Alert, Box,
    Button,
    Card as MuiCard,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import type { FC } from 'react';
import { APIPersonSoftCreditPull } from 'src/api';
import { Card } from 'src/backend';
import { LoadingButton } from 'src/components/common/v2/LoadingButton';
import { CardStatus } from 'src/constants/loan';
import { PRINCIPAL_VIEW } from 'src/constants/person';
import { ActionStatus } from 'src/constants/ui';
import { useLoans } from 'src/hooks/use-loans';
import { createPersonStatusSelector, postPersonSoftCreditPull } from 'src/slices/person';
import { applicantSelector, getView } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { schema } from './soft-credit-check-form.validation';

interface SoftCreditCheckFormProps extends Pick<Card<any>, 'status'> { }

export const SoftCreditCheckForm: FC<SoftCreditCheckFormProps> = (props) => {
    // redux state
    const personStatus = useSelector(createPersonStatusSelector);
    const applicant = useSelector(applicantSelector);

    const { id: personId } = applicant || {};
    const dispatch = useDispatch();

    const theme = useTheme();
    const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'));
    const { allLoans: [firstLoan,] } = useLoans({
        fetchLoans: true
    });
    const isEditable = [CardStatus.PROCESSING, null].includes(props.status as any);
    const isProcessed = props.status === CardStatus.PROCESSED;

    return (
        <Formik
            initialValues={{ signature: '', consent: CardStatus.PROCESSED, personId }}
            validationSchema={schema}
            onSubmit={async (values, {
                setSubmitting
            }): Promise<void> => {
                setSubmitting(true);
                await dispatch(postPersonSoftCreditPull(values as APIPersonSoftCreditPull));
                await dispatch(getView({ viewType: PRINCIPAL_VIEW }));
                setSubmitting(false);
            }}>
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }): JSX.Element => {

                return (
                    <form onSubmit={handleSubmit}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MuiCard sx={{
                                boxShadow: 0,
                                overflow: 'visible',
                                border: `solid 1px ${themeV2.neutral.grey[80]}`,
                            }}>
                                <CardHeader
                                    sx={{ position: 'sticky', top: 0, zIndex: '2', p: 2 }}
                                    titleTypographyProps={{
                                        variant: 'h6',
                                        sx: {
                                            fontWeight: 500,
                                            color: themeV2.colors.blue[900],
                                        }
                                    }}
                                    title='Soft Credit Check Consent'
                                    subheader="Soft credit pull - will not affect your credit score"
                                />
                                <CardContent>
                                    {isProcessed && <Check />}
                                    {isEditable &&
                                        <>
                                            <Box sx={{ mb: 2 }}>
                                                <Box sx={{ py: 1 }}>
                                                    I, <Typography fontWeight='bold' color='primary' display='inline'>{getUserDisplayName(applicant)}</Typography>,
                                                    hereby authorize <Typography fontWeight='bold' color='success.main' display='inline'>{firstLoan?.lender?.name}</Typography> to verify my past and present employment, earnings records,
                                                    bank accounts, stockholdings, and any other asset balances that are needed to
                                                    process my commercial loan application. I further authorize under the Fair Credit
                                                    Reporting Act <Typography fontWeight='bold' color='success.main' display='inline'>{firstLoan?.lender?.name}</Typography> to order my consumer credit report from a consumer reporting
                                                    agency and verify other credit or personal background information, including past
                                                    and present mortgage and landlord references.
                                                </Box>
                                                <Box sx={{ py: 1 }}>
                                                    The information <Typography fontWeight='bold' color='success.main' display='inline'>{firstLoan?.lender?.name}</Typography> obtains is only to be used in the processing of my
                                                    commercial loan application for a commercial loan. <Typography fontWeight='bold' color='success.main' display='inline'>{firstLoan?.lender?.name}</Typography> is also authorized
                                                    to share my personal information (but not my consumer report) with other financial
                                                    institutions.
                                                </Box>
                                                <Box sx={{ py: 1 }}>
                                                    By signing below, the undersigned agrees to all the terms and conditions.
                                                </Box>

                                            </Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={12}>
                                                    <TextField
                                                        autoFocus={isSmAndUp}
                                                        error={Boolean(touched.signature && errors.signature)}
                                                        fullWidth
                                                        helperText={touched.signature && errors.signature}
                                                        label="Initials"
                                                        name="signature"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.signature}
                                                        variant="outlined"
                                                        inputProps={{
                                                            style: { textTransform: "uppercase" },
                                                            "data-testid": "signature"
                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </>}
                                    {Object.keys(errors).length > 0 && <Alert severity='error' sx={{ mt: 3 }}>
                                        There are items that require your attention.
                                    </Alert>}
                                    {isEditable && <Box sx={{ mt: 3, }} >
                                        <LoadingButton
                                            color="primary"
                                            loading={isSubmitting || personStatus === ActionStatus.loading}
                                            type="submit"
                                            sx={{ minWidth: 162 }}
                                            data-testid='submit-btn'
                                            fullWidth={!isSmAndUp}
                                            variant="contained">
                                            {personStatus === ActionStatus.loading ? 'Loading...' : 'Submit'}
                                        </LoadingButton>
                                    </Box>}
                                </CardContent>
                            </MuiCard>
                        </LocalizationProvider>
                    </form>
                )
            }}
        </Formik>
    );
};

export default SoftCreditCheckForm;
