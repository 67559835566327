import Box from '@mui/material/Box';
import type { NextPage } from 'next';
import Head from 'next/head';
import { AuthGuard } from 'src/components/auth/auth-guard';
import { BorrowerOnboarding } from 'src/components/borrower-onboarding';
import { DashboardLayout } from 'src/components/dashboard/dashboard-layout';
import { LoansFilters } from 'src/components/loans/loans-filters';
import { LoansKanbanMapper } from 'src/components/loans-kanban';
import { SplashScreen } from 'src/components/splash-screen';
import { InvitesBanner } from 'src/components/user/invites-banner';
import { LoanRoleGuard } from 'src/components/user/loan-role-guard';
import { UserRoleGuard } from 'src/components/user/user-role-guard';
import { LoanStageCategory } from 'src/constants/loan';
import {
  LENDER_VIEW,
  PRINCIPAL_KANBAN_VIEW,
  PRINCIPAL_VIEW,
} from 'src/constants/person';
import { PageLabel } from 'src/constants/ui';
import { Permission } from 'src/constants/user';
import { CustomizedTableProvider } from 'src/contexts/customized-table-context';
import { KanbanLoansProvider } from 'src/contexts/loans-kanban-context';
import { useDashboardLayout } from 'src/hooks/use-dashboard-layout';
import { useNavbarTitle } from 'src/hooks/use-navbar-title';
import { useRedirectBorrowerSingleLoan } from 'src/hooks/use-redirect-borrower-single-loan';
import { useUser } from 'src/hooks/use-user';
import { lenderIdSelector } from 'src/slices/view';
import { useSelector } from 'src/store';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { roleCan } from 'src/utils/user/role-can';

const ViewTypeComponent = {
  [LENDER_VIEW]: <LoansKanbanMapper />,
  [PRINCIPAL_VIEW]: <BorrowerOnboarding />,
  [PRINCIPAL_KANBAN_VIEW]: <LoansKanbanMapper />,
};

const Home: NextPage = () => {
  useNavbarTitle(PageLabel.Origination);
  const viewType = useDashboardLayout();
  const lenderId = useSelector(lenderIdSelector);
  useRedirectBorrowerSingleLoan();
  const { userRole } = useUser();

  if (!viewType) {
    return <SplashScreen />;
  }

  const isLender = viewType === LENDER_VIEW;
  return (
    <>
      <Head>
        <title>{PageLabel.Origination}</title>
      </Head>
      <UserRoleGuard permission={Permission.SeePendingInvites}>
        <InvitesBanner />
      </UserRoleGuard>
      <KanbanLoansProvider
        phaseCategory={LoanStageCategory.ORIGINATION}
        companyId={lenderId}
      >
        <CustomizedTableProvider>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: themeV2.neutral.grey[7],
              '& .loans-kanban-container': {
                height: `calc(100vh - ${isLender && roleCan(userRole, Permission.CrudLoan)
                    ? '140'
                    : '56'
                  }px)`,
              },
            }}
          >
            <LoanRoleGuard permission={Permission.FilterLoans}>
              <LoansFilters lenderId={lenderId} />
            </LoanRoleGuard>
            {ViewTypeComponent[viewType]}
          </Box>
        </CustomizedTableProvider>
      </KanbanLoansProvider>
    </>
  );
};

Home.getLayout = (page) => (
  <AuthGuard>
    <DashboardLayout>{page}</DashboardLayout>
  </AuthGuard>
);

export default Home;
